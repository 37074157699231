.tags-input {
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}
.tags-input input {
    background: transparent;
    border: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100%;
}
.tag {
    background-color: #20232a;
    border-radius: 2px;
    border: 1px solid #20232a;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}
  
button.remove {
    display: inline-block;
    margin-left: 3px;
    padding: 0;
    width: 13px;
    height: 13px;
    background-color: #fff;
    color: #000;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    outline: none;
    user-select: none;
}
button.remove:after {
    content: "×";
    width: 5px;
    height: 5px;
    line-height: 15px;
    text-align: center;
}
  